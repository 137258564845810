import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import { FaClock, FaChartLine, FaRocket } from "react-icons/fa";

const benefits = [
  {
    id: 1,
    icon: FaClock,
    title: "Gagnez du temps",
    subtitle: "De l'étude à la pose",
    description:
      "Optimisez votre processus complet, de l'étude technique jusqu'à l'installation finale",
  },
  {
    id: 2,
    icon: FaChartLine,
    title: "Augmentez vos ventes",
    subtitle: "Avec des propositions impactantes",
    description:
      "Développez votre activité grâce à des propositions commerciales à forte valeur ajoutée",
  },
  {
    id: 3,
    icon: FaRocket,
    title: "Accélérez vos démarches",
    subtitle: "Sans effort supplémentaire",
    description:
      "Simplifiez vos procédures administratives sans augmenter votre charge de travail",
  },
];

const BenefitCard = ({
  benefit,
  index,
}: {
  benefit: (typeof benefits)[0];
  index: number;
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: cardRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 0.5, 1], [100, 0, -100]);

  const scale = useTransform(scrollYProgress, [0, 0.5, 1], [0.8, 1, 0.8]);

  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);

  const rotate = useTransform(scrollYProgress, [0, 0.5, 1], [10, 0, -10]);

  const IconComponent = benefit.icon;

  return (
    <motion.div
      ref={cardRef}
      style={{
        y,
        scale,
        opacity,
        rotateX: rotate,
        zIndex: benefits.length - index,
      }}
      className="relative desktop:w-2/3 laptop:w-2/3 tablet:w-4/5 mobile:w-full mx-auto mb-24"
    >
      <div className="relative bg-white rounded-2xl p-8 shadow-xl border-2 border-yellow-600/20">
        <motion.div
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="absolute -top-6 left-8 bg-gradient-to-br from-yellow-500 to-yellow-600 p-4 rounded-xl shadow-lg"
        >
          <IconComponent className="text-3xl text-white" />
        </motion.div>

        <div className="ml-20">
          <motion.h3
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="text-2xl font-bold text-slate-800 mb-2"
          >
            {benefit.title}
          </motion.h3>
          <motion.p
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-yellow-600 font-semibold mb-4"
          >
            {benefit.subtitle}
          </motion.p>
          <motion.p
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="text-slate-600"
          >
            {benefit.description}
          </motion.p>
        </div>
      </div>
    </motion.div>
  );
};

const ConnectingLine = () => (
  <div className="absolute left-12 top-0 bottom-0 desktop:block laptop:block tablet:hidden mobile:hidden">
    <div className="relative h-full">
      <div className="absolute left-0 top-0 bottom-0 w-0.5 bg-gradient-to-b from-yellow-600/0 via-yellow-600/50 to-yellow-600/0" />
      <motion.div
        initial={{ height: "0%" }}
        whileInView={{ height: "100%" }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        className="absolute left-0 top-0 w-0.5 bg-yellow-600"
      />
    </div>
  </div>
);

const KeyBenefits = () => {
  return (
    <div className="bg-slate-50/50 py-32 relative overflow-hidden">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-24"
        >
          <h2 className="desktop:text-4xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-slate-800 mb-4">
            Une solution{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
              complète
            </span>{" "}
            pour votre réussite
          </h2>
        </motion.div>

        <div className="relative">
          <ConnectingLine />
          {benefits.map((benefit, index) => (
            <BenefitCard key={benefit.id} benefit={benefit} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyBenefits;
