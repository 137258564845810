import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
// import Pricing from "./pages/Pricing";
import CaseStudyDetail from "./pages/CaseStudyDetail";
import LegalPage from "./pages/LegalPage";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />{" "}
        {/* <Route path="/tarifs" element={<Pricing />} /> */}
        <Route path="/etude-de-cas/ecovea" element={<CaseStudyDetail />} />
        <Route path="/mentions-obligatoires" element={<LegalPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
