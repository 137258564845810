import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

const companies = [
  {
    name: "EBMT Energies",
    logo: "https://createsiteai.b-cdn.net/c/_/d14cb73d-3640-4072-8163-cc4b4204b992.svg",
  },
  {
    name: "ECOVEA",
    logo: "https://createsiteai.b-cdn.net/c/_/38da728e-28be-4704-85d2-05d94da0f44b",
  },
  {
    name: "Recasol",
    logo: "https://createsiteai.b-cdn.net/c/_/36e5f614-b0c8-4832-b7ad-140ebc8660ef.svg",
  },
  {
    name: "Batisol33",
    logo: "https://createsiteai.b-cdn.net/c/_/0a77f04d-97f7-4d94-baab-c503d748380d.svg",
  },
  {
    name: "Tecsol",
    logo: "https://createsiteai.b-cdn.net/c/_/d6e3e6a1-c0be-408c-80f9-5cbad03f5db8.svg",
  },
  {
    name: "VIVAsol",
    logo: "https://createsiteai.b-cdn.net/c/_/8a25e3fc-28bd-498f-b3fb-1744ce8804cd.svg",
  },
  {
    name: "Perfosol",
    logo: "https://createsiteai.b-cdn.net/c/_/5214be84-0f7b-403e-9508-2d54582e40b5.svg",
  },
];

const LogoSlider = () => {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-8"
        >
          <p className="text-lg text-slate-600">Ils nous font confiance</p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <Swiper
            modules={[Autoplay]}
            slidesPerView={4}
            spaceBetween={30}
            loop={true}
            speed={3000}
            autoplay={{
              delay: 0,
              disableOnInteraction: true,
            }}
            breakpoints={{
              320: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              1024: { slidesPerView: 4 },
              1440: { slidesPerView: 5 },
            }}
            className="py-4"
          >
            {companies.map((company, index) => (
              <SwiperSlide key={index}>
                <div className="flex items-center justify-center p-4">
                  <img
                    src={company.logo}
                    alt={`${company.name} logo`}
                    className="h-12 w-32 object-contain filter grayscale hover:grayscale-0 transition-all duration-300"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </motion.div>
      </div>
    </div>
  );
};

export default LogoSlider;
