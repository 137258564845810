import { motion } from "framer-motion";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LegalPage = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <div className="pt-16">
      <div className="bg-white">
        <div className="container mx-auto px-4 max-w-4xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="py-24"
          >
            <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400 mb-16 text-center">
              Mentions obligatoires
            </h1>

            <section
              id="mentions-legales"
              className="mb-24 bg-white rounded-2xl p-8 shadow-lg border-2 border-yellow-600/20"
            >
              <h2 className="text-3xl font-bold text-slate-800 mb-8 flex items-center gap-4 before:content-[''] before:h-1 before:w-8 before:bg-yellow-600/20 before:rounded-full after:content-[''] after:h-1 after:flex-1 after:bg-yellow-600/20 after:rounded-full">
                1. Mentions légales
              </h2>
              <div className="prose prose-slate max-w-none">
                <p>
                  EtudeSolaire est édité par la société EtudeSolaire SAS, au
                  capital de 1000€, immatriculée au RCS de Paris sous le numéro
                  XXX XXX XXX.
                </p>
                <p>Siège social : 27 rue de l'oiseau, Dubaï</p>
                <p>Directeur de la publication : XXXXX</p>
                <p>Contact : contact@etudesolaire.com</p>
              </div>
            </section>

            <section
              id="politique-confidentialite"
              className="mb-24 bg-white rounded-2xl p-8 shadow-lg border-2 border-yellow-600/20"
            >
              <h2 className="text-3xl font-bold text-slate-800 mb-8 flex items-center gap-4 before:content-[''] before:h-1 before:w-8 before:bg-yellow-600/20 before:rounded-full after:content-[''] after:h-1 after:flex-1 after:bg-yellow-600/20 after:rounded-full">
                2. Politique de confidentialité
              </h2>
              <div className="prose prose-slate max-w-none">
                <p>
                  EtudeSolaire s'engage à protéger la confidentialité de vos
                  données personnelles conformément au Règlement Général sur la
                  Protection des Données (RGPD).
                </p>
                <p>
                  Les données collectées sont utilisées uniquement dans le cadre
                  de nos services et ne sont jamais partagées avec des tiers
                  sans votre consentement explicite.
                </p>
              </div>
              className="bg-white rounded-2xl p-8 shadow-lg border-2
              border-yellow-600/20"
            </section>

            <section id="cgv-cgu">
              <h2 className="text-3xl font-bold text-slate-800 mb-8 flex items-center gap-4 before:content-[''] before:h-1 before:w-8 before:bg-yellow-600/20 before:rounded-full after:content-[''] after:h-1 after:flex-1 after:bg-yellow-600/20 after:rounded-full">
                3. CGV / CGU
              </h2>
              <div className="prose prose-slate max-w-none">
                <p>
                  Les présentes conditions générales régissent l'utilisation du
                  service EtudeSolaire et définissent les droits et obligations
                  des parties.
                </p>
                <p>
                  En utilisant notre service, vous acceptez sans réserve
                  l'ensemble de ces conditions.
                </p>
              </div>
            </section>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default LegalPage;
