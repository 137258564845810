import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-white border-t border-slate-200/80">
      <div className="container mx-auto px-4 py-16">
        <div className="desktop:grid laptop:grid tablet:grid mobile:flex desktop:grid-cols-4 laptop:grid-cols-4 tablet:grid-cols-2 gap-8 mobile:flex-col mobile:gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="flex flex-col gap-4"
          >
            <Link to="/" className="text-2xl font-bold text-slate-800">
              Etude
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
                Solaire
              </span>
            </Link>
            <p className="text-slate-600">
              Logiciel tout-en-un conçu pour les installateurs photovoltaïques.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="flex flex-col gap-4"
          >
            <h3 className="font-semibold text-slate-800">Contact</h3>
            <div className="flex flex-col gap-2">
              <a
                href="mailto:contact@etudesolaire.com"
                className="text-slate-600 hover:text-yellow-600"
              >
                contact@etudesolaire.com
              </a>
              <p className="text-slate-600">
                Adresse : 27 rue de l'oiseau, Dubaï
              </p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="flex flex-col gap-4"
          >
            <h3 className="font-semibold text-slate-800">Liens utiles</h3>
            <div className="flex flex-col gap-2">
              <Link
                to="/mentions-obligatoires#mentions-legales"
                className="text-slate-600 hover:text-yellow-600"
              >
                Mentions légales
              </Link>
              <Link
                to="/mentions-obligatoires#politique-confidentialite"
                className="text-slate-600 hover:text-yellow-600"
              >
                Politique de confidentialité
              </Link>
              <Link
                to="/mentions-obligatoires#cgv-cgu"
                className="text-slate-600 hover:text-yellow-600"
              >
                CGV / CGU
              </Link>
            </div>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="mt-12 pt-8 border-t border-slate-200/80 text-center"
        >
          <p className="text-slate-600">© Copyright EtudeSolaire 2024</p>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
