import { motion } from "framer-motion";
import { useState } from "react";
import useResponsiveLayout from "../hooks/useResponsiveLayout";
import { useFormspark } from "@formspark/use-formspark";
import { Switch } from "@headlessui/react";

const activityTypes = ["Installateur PV", "Bureau d'études"];

const projectTypes = ["Résidentiel", "Tertiaire", "Industriel", "Agricole"];

const projectVolumes = ["1-5", "6-10", "11-20", "21+"];

const FORMSPARK_FORM_ID = "fqz8uucwf";
const TrialRequest = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const deviceType = useResponsiveLayout();
  const [submit] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phone: "",
    activities: [] as string[],
    projectVolume: "",
    projectTypes: [] as string[],
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await submit({
        ...formData,
        _email: {
          to: "marc.assaf@edhec.com",
          subject: "Nouvelle demande d'essai EtudeSolaire",
          template: `
            Nouvelle demande d'essai reçue :
            
            Nom: ${formData.firstName} ${formData.lastName}
            Entreprise: ${formData.companyName}
            Email: ${formData.email}
            Téléphone: ${formData.phone}
            
            Type(s) d'activité: ${formData.activities.join(", ")}
            Nombre de projets PV/mois: ${formData.projectVolume}
            Type(s) de projet: ${formData.projectTypes.join(", ")}
          `,
        },
      });
      setIsSuccess(true);
    } catch (error) {
      setIsSuccess(false);
      console.error("Form submission error:", error);
    }
  };

  return (
    <div id="trial-form" className="bg-white py-24">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="desktop:text-4xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-slate-800 mb-4">
            Demandez un{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
              essai gratuit
            </span>
          </h2>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="max-w-2xl mx-auto"
        >
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="flex gap-4">
              <div className="relative flex-1">
                <input
                  type="text"
                  id="firstName"
                  required
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                  className="w-full px-4 py-3 border-2 border-slate-200 rounded-lg focus:border-yellow-600 focus:ring-0 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="firstName"
                  className="absolute left-4 -top-2.5 bg-white px-2 text-sm text-slate-600 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:text-sm"
                >
                  Prénom *
                </label>
              </div>
              <div className="relative flex-1">
                <input
                  type="text"
                  id="lastName"
                  required
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                  className="w-full px-4 py-3 border-2 border-slate-200 rounded-lg focus:border-yellow-600 focus:ring-0 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="lastName"
                  className="absolute left-4 -top-2.5 bg-white px-2 text-sm text-slate-600 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:text-sm"
                >
                  Nom *
                </label>
              </div>
            </div>

            <div className="relative">
              <input
                type="text"
                id="companyName"
                required
                value={formData.companyName}
                onChange={(e) =>
                  setFormData({ ...formData, companyName: e.target.value })
                }
                className="w-full px-4 py-3 border-2 border-slate-200 rounded-lg focus:border-yellow-600 focus:ring-0 peer"
                placeholder=" "
              />
              <label
                htmlFor="companyName"
                className="absolute left-4 -top-2.5 bg-white px-2 text-sm text-slate-600 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:text-sm"
              >
                Nom de l'entreprise *
              </label>
            </div>

            <div className="flex gap-4">
              <div className="relative flex-1">
                <input
                  type="email"
                  id="email"
                  required
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  className="w-full px-4 py-3 border-2 border-slate-200 rounded-lg focus:border-yellow-600 focus:ring-0 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="email"
                  className="absolute left-4 -top-2.5 bg-white px-2 text-sm text-slate-600 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:text-sm"
                >
                  Adresse email *
                </label>
              </div>
              <div className="relative flex-1">
                <input
                  type="tel"
                  id="phone"
                  required
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  className="w-full px-4 py-3 border-2 border-slate-200 rounded-lg focus:border-yellow-600 focus:ring-0 peer"
                  placeholder=" "
                />
                <label
                  htmlFor="phone"
                  className="absolute left-4 -top-2.5 bg-white px-2 text-sm text-slate-600 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-focus:-top-2.5 peer-focus:text-sm"
                >
                  {deviceType === "mobile"
                    ? "Téléphone *"
                    : "Numéro de téléphone *"}
                </label>
              </div>
            </div>

            <div className="space-y-4">
              <label className="block text-sm font-medium text-slate-700">
                Type(s) d'activité
              </label>
              <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1 gap-4">
                {activityTypes.map((type) => (
                  <Switch.Group key={type}>
                    <div className="flex items-center justify-between p-4 border-2 border-slate-200 rounded-lg hover:border-yellow-600/20 transition-colors">
                      <Switch.Label className="text-slate-600">
                        {type}
                      </Switch.Label>
                      <Switch
                        checked={formData.activities.includes(type)}
                        onChange={(checked) => {
                          setFormData({
                            ...formData,
                            activities: checked
                              ? [...formData.activities, type]
                              : formData.activities.filter((t) => t !== type),
                          });
                        }}
                        className={`${
                          formData.activities.includes(type)
                            ? "bg-yellow-600"
                            : "bg-slate-200"
                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
                      >
                        <span
                          className={`${
                            formData.activities.includes(type)
                              ? "translate-x-6"
                              : "translate-x-1"
                          } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-700 mb-4">
                Nombre de projets PV / mois
              </label>
              <div className="grid desktop:grid-cols-4 laptop:grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-2 gap-4">
                {projectVolumes.map((volume) => (
                  <button
                    key={volume}
                    type="button"
                    onClick={() =>
                      setFormData({ ...formData, projectVolume: volume })
                    }
                    className={`p-4 border-2 rounded-lg text-center transition-all ${
                      formData.projectVolume === volume
                        ? "border-yellow-600 bg-yellow-50 text-yellow-600"
                        : "border-slate-200 text-slate-600 hover:border-yellow-600/20"
                    }`}
                  >
                    {volume}
                  </button>
                ))}
              </div>
            </div>

            <div className="space-y-4">
              <label className="block text-sm font-medium text-slate-700">
                Type de projet
              </label>
              <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-2 gap-4">
                {projectTypes.map((type) => (
                  <Switch.Group key={type}>
                    <div className="flex items-center justify-between p-4 border-2 border-slate-200 rounded-lg hover:border-yellow-600/20 transition-colors">
                      <Switch.Label className="text-slate-600">
                        {type}
                      </Switch.Label>
                      <Switch
                        checked={formData.projectTypes.includes(type)}
                        onChange={(checked) => {
                          setFormData({
                            ...formData,
                            projectTypes: checked
                              ? [...formData.projectTypes, type]
                              : formData.projectTypes.filter((t) => t !== type),
                          });
                        }}
                        className={`${
                          formData.projectTypes.includes(type)
                            ? "bg-yellow-600"
                            : "bg-slate-200"
                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
                      >
                        <span
                          className={`${
                            formData.projectTypes.includes(type)
                              ? "translate-x-6"
                              : "translate-x-1"
                          } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                ))}
              </div>
            </div>

            <button
              type="submit"
              className="w-full px-8 py-4 bg-yellow-600 text-white rounded-lg shadow-lg shadow-yellow-600/20 hover:shadow-xl hover:shadow-yellow-600/30 transition-all duration-300 font-semibold"
            >
              Demander mon essai gratuit
            </button>
            {isSuccess && (
              <p className="text-green-600 mt-4 text-center">
                Votre demande a bien été envoyée
              </p>
            )}
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default TrialRequest;
