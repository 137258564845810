import React from "react";
import ResponsiveContainer from "../components/ResponsiveContainer";
import Hero from "../components/Hero";
import KeyStats from "../components/KeyStats";
import MainFeatures from "../components/MainFeatures";
import IntegratedBrands from "../components/IntegratedBrands";
import ProductDemoSections from "../components/ProductDemoSections";
import ROISection from "../components/ROISection";
import CaseStudies from "../components/CaseStudies";
import TrialRequest from "../components/TrialRequest";
import LogoSlider from "../components/LogoSlider";
import KeyBenefits from "../components/KeyBenefits";

const Home: React.FC = () => {
  return (
    <>
      <ResponsiveContainer>
        <Hero />
      </ResponsiveContainer>
      <ResponsiveContainer>
        <LogoSlider />
      </ResponsiveContainer>
      <ResponsiveContainer className="mobile:mt-12">
        <MainFeatures />
      </ResponsiveContainer>
      <ResponsiveContainer>
        <ROISection />
      </ResponsiveContainer>
      <ResponsiveContainer>
        <IntegratedBrands />
      </ResponsiveContainer>
      <ResponsiveContainer>
        <KeyStats />
      </ResponsiveContainer>
      <ResponsiveContainer>
        <CaseStudies />
      </ResponsiveContainer>
      <ResponsiveContainer>
        <KeyBenefits />
      </ResponsiveContainer>
      <ResponsiveContainer>
        <TrialRequest />
      </ResponsiveContainer>
    </>
  );
};

export default Home;
