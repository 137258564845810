import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { FaUserTie, FaFileAlt, FaRulerCombined, FaTasks } from "react-icons/fa";
import useResponsiveLayout from "../hooks/useResponsiveLayout";

const features = [
  {
    id: 1,
    emoji: <FaUserTie className="text-4xl text-yellow-600" />,
    title: "Module CRM et assistance commerciale",
    subtitle: "Assistant commercial",
    points: [
      "Gestion avancée des prospects et clients",
      "Suivi des opportunités commerciales",
    ],
    image:
      "https://createsiteai.b-cdn.net/c/_/7bfbda70-0da3-456e-beab-66c28850d455.webp",
  },
  {
    id: 2,
    emoji: <FaFileAlt className="text-4xl text-yellow-600" />,
    title: "Module de gestion administrative",
    subtitle: "Gestion administrative",
    points: [
      "Automatisation des tâches administratives",
      "Gestion centralisée des documents",
    ],
    image:
      "https://createsiteai.b-cdn.net/c/_/0b8a3aa6-eeee-4d90-9fca-87d0a0e0f790.webp",
  },
  {
    id: 3,
    emoji: <FaRulerCombined className="text-4xl text-yellow-600" />,
    title: "Module de calepinage intelligent",
    subtitle: "Calepinage automatisé",
    points: [
      "Conception automatisée des installations",
      "Optimisation des implantations",
    ],
    image:
      "https://createsiteai.b-cdn.net/c/_/a5bda8a0-2a49-48cc-be4e-25ed55b2c44a.webp",
  },
  {
    id: 4,
    emoji: <FaTasks className="text-4xl text-yellow-600" />,
    title: "Module de management de projets",
    subtitle: "Gestion de projet",
    points: [
      "Suivi en temps réel des chantiers",
      "Coordination des équipes terrain",
    ],
    image:
      "https://createsiteai.b-cdn.net/c/_/263825c2-aa55-4790-9678-cd52ef39c61d.webp",
  },
];

const MainFeatures = () => {
  const deviceType = useResponsiveLayout();
  const [activeFeature, setActiveFeature] = useState<number | null>(null);

  useEffect(() => {
    if (deviceType !== "mobile") {
      setActiveFeature(features[0].id);
    } else {
      setActiveFeature(null);
    }
  }, [deviceType]);

  return (
    <div className="bg-white desktop:py-24 laptop:py-24 tablet:py-24 mobile:py-12">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="desktop:text-4xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-slate-800 mb-4">
            Conçu pour les{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
              installateurs
            </span>
          </h2>
        </motion.div>

        <div className="desktop:flex laptop:flex tablet:block mobile:block gap-8 mobile:space-y-4">
          <div className="desktop:w-1/3 laptop:w-1/3 tablet:w-full mobile:w-full desktop:mb-0 laptop:mb-0 tablet:mb-8 mobile:mb-0">
            <div className="space-y-4 mobile:space-y-0">
              {features.map((feature) => (
                <div className="mobile:space-y-4">
                  <motion.button
                    key={feature.id}
                    onClick={() =>
                      setActiveFeature(
                        activeFeature === feature.id ? null : feature.id,
                      )
                    }
                    className={`w-full text-left p-6 rounded-xl transition-all duration-300 ${
                      activeFeature === feature.id
                        ? "bg-white shadow-lg shadow-yellow-600/5 border-2 border-yellow-600/20"
                        : "bg-slate-50 hover:bg-white hover:shadow-lg hover:shadow-yellow-600/5"
                    }`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <div className="flex items-center gap-4">
                      <div className="p-3 rounded-lg bg-yellow-50 group-hover:bg-yellow-100 transition-colors duration-300">
                        {feature.emoji}
                      </div>
                      <div>
                        <h3 className="font-bold text-slate-800">
                          {feature.subtitle}
                        </h3>
                        <p className="text-sm text-yellow-600">
                          {feature.title}
                        </p>
                      </div>
                    </div>
                  </motion.button>
                  {deviceType === "mobile" && activeFeature === feature.id && (
                    <motion.div
                      key={feature.id}
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="w-full"
                    >
                      <div className="bg-white rounded-2xl shadow-xl overflow-hidden border-2 border-yellow-600/20 mt-4">
                        <div className="p-8">
                          <h3 className="text-2xl font-bold text-slate-800 mb-4">
                            {feature.title}
                          </h3>
                          <p className="text-yellow-600 font-medium mb-6">
                            {feature.subtitle}
                          </p>
                          <ul className="space-y-4 mb-8">
                            {feature.points.map((point, idx) => (
                              <li key={idx} className="flex items-center gap-3">
                                <div className="w-1.5 h-1.5 rounded-full bg-yellow-600" />
                                <span className="text-slate-600">{point}</span>
                              </li>
                            ))}
                          </ul>
                          <Link
                            to="#trial-form"
                            onClick={(e) => {
                              e.preventDefault();
                              document
                                .getElementById("trial-form")
                                ?.scrollIntoView({ behavior: "smooth" });
                            }}
                            className="inline-flex items-center gap-2 px-6 py-3 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition-colors duration-300"
                          >
                            Je découvre la solution
                            <svg
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {!["mobile"].includes(deviceType) && activeFeature && (
            <motion.div
              key={activeFeature}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="desktop:w-2/3 laptop:w-2/3 tablet:w-full mobile:w-full"
            >
              <div className="bg-white rounded-2xl shadow-xl overflow-hidden border-2 border-yellow-600/20">
                <div className="desktop:flex laptop:flex tablet:block mobile:block">
                  <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full p-8">
                    <h3 className="text-2xl font-bold text-slate-800 mb-4">
                      {features.find((f) => f.id === activeFeature)?.title}
                    </h3>
                    <p className="text-yellow-600 font-medium mb-6">
                      {features.find((f) => f.id === activeFeature)?.subtitle}
                    </p>
                    <ul className="space-y-4 mb-8">
                      {features
                        .find((f) => f.id === activeFeature)
                        ?.points.map((point, idx) => (
                          <li key={idx} className="flex items-center gap-3">
                            <div className="w-1.5 h-1.5 rounded-full bg-yellow-600" />
                            <span className="text-slate-600">{point}</span>
                          </li>
                        ))}
                    </ul>
                    <Link
                      to="#trial-form"
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById("trial-form")
                          ?.scrollIntoView({ behavior: "smooth" });
                      }}
                      className="inline-flex items-center gap-2 px-6 py-3 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition-colors duration-300"
                    >
                      Je découvre la solution
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    </Link>
                  </div>
                  <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full relative">
                    <div className="absolute inset-0 bg-gradient-to-br from-slate-50/90 via-slate-50/80 to-white/70" />
                    <img
                      src={features.find((f) => f.id === activeFeature)?.image}
                      alt={features.find((f) => f.id === activeFeature)?.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainFeatures;
