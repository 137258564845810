import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const KeyStats = () => {
  return (
    <div className="relative bg-slate-50 py-24">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="desktop:flex laptop:flex tablet:block mobile:block items-center gap-12"
        >
          <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full">
            <img
              src="https://createsiteai.b-cdn.net/c/_/e173e664-f4f8-4f7b-bae1-3a4f447685f3.webp"
              alt="Professionnel présentant une étude solaire"
              className="rounded-2xl shadow-xl shadow-yellow-600/10"
            />
          </div>
          <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full desktop:mt-0 laptop:mt-0 tablet:mt-12 mobile:mt-12">
            <h2 className="desktop:text-4xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-medium text-slate-800 mb-8">
              <span className="text-yellow-600">+9000 études</span> générées sur{" "}
              <span className="text-2xl font-bold text-slate-800">
                Etude
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
                  Solaire
                </span>
              </span>
            </h2>
            <Link
              to="#trial-form"
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("trial-form")
                  ?.scrollIntoView({ behavior: "smooth" });
              }}
              className="inline-flex items-center gap-3 px-8 py-4 bg-yellow-600 text-white rounded-lg shadow-lg shadow-yellow-600/20 hover:shadow-xl hover:shadow-yellow-600/30 transition-all duration-300"
            >
              Essai gratuit 30 jours
              <svg
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default KeyStats;
