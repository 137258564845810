import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const brands = [
  {
    name: "Meyer Burger",
    logo: "https://createsiteai.b-cdn.net/c/_/48bb878d-7baf-4c0e-be72-e97174d283fe",
  },
  {
    name: "SunPower",
    logo: "https://createsiteai.b-cdn.net/c/_/ac67f233-1d11-4919-b1c4-c6b542b3a26f",
  },
  {
    name: "LG Solar",
    logo: "https://createsiteai.b-cdn.net/c/_/6d924120-af60-4d21-8fee-c338797da904",
  },
  {
    name: "REC Solar",
    logo: "https://createsiteai.b-cdn.net/c/_/7548945c-68b3-43be-989f-c139f6a0e99e",
  },
  {
    name: "Enphase",
    logo: "https://createsiteai.b-cdn.net/c/_/27ece3e3-6e06-4acd-a664-71f061501e6e",
  },
  {
    name: "SolarEdge",
    logo: "https://createsiteai.b-cdn.net/c/_/d9710bb2-0332-4ed2-b0d3-140835557600",
  },
  {
    name: "Q CELLS",
    logo: "https://createsiteai.b-cdn.net/c/_/9f337977-6500-41a5-8d8a-f65f667cb1d2",
  },
  {
    name: "JinkoSolar",
    logo: "https://createsiteai.b-cdn.net/c/_/30747b16-c95e-49d8-b9ea-7a1709f6ad49",
  },
  {
    name: "Canadian Solar",
    logo: "https://createsiteai.b-cdn.net/c/_/30a4ad4a-e0fc-4ab7-9827-c79819ea20ba",
  },
  {
    name: "Trina Solar",
    logo: "https://createsiteai.b-cdn.net/c/_/6f4a83ee-3b13-46e2-ba41-d94739810602",
  },
];

const IntegratedBrands = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <div className="relative bg-white py-24">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="desktop:mx-12 laptop:mx-12 tablet:mx-6 mobile:mx-4 rounded-3xl border-2 border-yellow-600/20 bg-gradient-to-br from-yellow-50/80 to-white shadow-2xl shadow-yellow-600/5"
        >
          <div className="relative px-8 py-16">
            <div className="absolute -top-5 left-1/2 -translate-x-1/2 bg-white px-8 py-2 rounded-full border-2 border-yellow-600/20 mobile:hidden">
              <h2 className="desktop:text-2xl laptop:text-2xl tablet:text-xl mobile:text-lg font-bold text-yellow-600 text-center">
                Une solution intégrée avec tous les fabricants
              </h2>
            </div>

            <div className="text-center mt-8 mb-16">
              <p className="text-xl text-slate-700">
                Plus de{" "}
                <span className="font-bold text-yellow-600">70 marques</span> et{" "}
                <span className="font-bold text-yellow-600">
                  2500 références
                </span>{" "}
                configurées
              </p>
            </div>

            <motion.div
              variants={container}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
              className="grid desktop:grid-cols-5 laptop:grid-cols-4 tablet:grid-cols-3 mobile:grid-cols-2 gap-8"
            >
              {brands.map((brand, index) => (
                <motion.div
                  key={index}
                  variants={item}
                  className="flex items-center justify-center p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                >
                  <img
                    src={brand.logo}
                    alt={brand.name}
                    className="h-12 w-auto object-contain filter grayscale hover:grayscale-0 transition-all duration-300"
                  />
                </motion.div>
              ))}
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default IntegratedBrands;
