import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Header = () => {
  return (
    <motion.header
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-sm border-b border-slate-200/80 flex flex-col"
    >
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="text-2xl font-bold text-slate-800">
            Etude
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
              Solaire
            </span>
          </Link>
          <div className="mobile:block desktop:hidden laptop:hidden tablet:hidden">
            <a
              href="https://etudesolaire.vercel.app/login"
              className="px-4 py-2 rounded-lg border-2 border-yellow-600/20 hover:border-yellow-600 transition-all duration-300 font-semibold text-yellow-600"
            >
              Se connecter
            </a>
          </div>
          <div className="desktop:flex laptop:flex tablet:flex mobile:hidden items-center gap-6">
            <a
              href="https://etudesolaire.vercel.app/login"
              className="px-6 py-2 rounded-lg border-2 border-yellow-600/20 hover:border-yellow-600 transition-all duration-300 font-semibold text-yellow-600"
            >
              Se connecter
            </a>
          </div>
        </div>
      </div>
    </motion.header>
  );
};

export default Header;
