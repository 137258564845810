import { motion } from "framer-motion";
import { FaClock, FaUsers, FaEuroSign } from "react-icons/fa";

const metrics = [
  {
    icon: FaUsers,
    value: "30%",
    label: "Taux de conversion",
    description: "Augmentation moyenne des signatures",
  },
  {
    icon: FaEuroSign,
    value: "40k€",
    label: "CA additionnel",
    description: "Par commercial et par an",
  },
  {
    icon: FaClock,
    value: "70%",
    label: "Gain de temps",
    description: "Sur la préparation des devis et études",
  },
];

const ROISection = () => {
  return (
    <div className="bg-white py-24">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="desktop:text-4xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-slate-800 mb-4">
            <span className="desktop:inline laptop:inline tablet:inline mobile:hidden">
              Un{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
                retour sur investissement
              </span>{" "}
              immédiat
            </span>
            <span className="desktop:hidden laptop:hidden tablet:hidden mobile:inline">
              Un{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
                ROI
              </span>{" "}
              immédiat
            </span>
          </h2>
        </motion.div>

        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-3 mobile:grid-cols-1 gap-8">
          {metrics.map((metric, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-slate-50 rounded-2xl p-8 text-center"
            >
              <metric.icon className="text-yellow-600 text-4xl mx-auto mb-4" />
              <p className="text-4xl font-bold text-slate-800 mb-2">
                {metric.value}
              </p>
              <p className="text-yellow-600 font-semibold mb-2">
                {metric.label}
              </p>
              <p className="text-slate-600 text-sm">{metric.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ROISection;
