import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const caseStudy = {
  company: "ECOVEA",
  logo: "https://createsiteai.b-cdn.net/c/_/38da728e-28be-4704-85d2-05d94da0f44b",
  image:
    "https://createsiteai.b-cdn.net/c/_/74f7f35f-dd9c-4f7c-bec0-2287c78fa8f1",
  metrics: [
    { label: "Installations/mois", value: "+45%" },
    { label: "Temps économisé", value: "20h" },
    { label: "Taux de conversion", value: "+38%" },
  ],
  testimonial:
    "EtudeSolaire a transformé notre façon de travailler. La solution nous permet d'être plus efficaces dans nos études techniques et plus convaincants dans nos propositions commerciales.",
};

const CaseStudies = () => {
  return (
    <div className="bg-white py-24">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="desktop:text-4xl laptop:text-4xl tablet:text-3xl mobile:text-2xl font-bold text-slate-800 mb-4">
            Notre étude de cas{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
              installateur
            </span>
          </h2>
          <p className="text-xl text-slate-600">
            Découvrez comment ECOVEA utilise EtudeSolaire au quotidien pour
            développer son entreprise
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="relative desktop:h-[600px] laptop:h-[600px] tablet:h-[500px] mobile:h-[400px] rounded-2xl overflow-hidden"
        >
          <div className="absolute inset-0">
            <img
              src={caseStudy.image}
              alt={caseStudy.company}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-slate-900 via-slate-900/80 to-transparent" />
          </div>{" "}
          <Link
            to="/etude-de-cas/ecovea"
            className="relative h-full flex flex-col justify-end desktop:p-12 laptop:p-12 tablet:p-8 mobile:p-6 hover:bg-slate-900/10 transition-colors duration-300"
          >
            <img
              src={caseStudy.logo}
              alt={`${caseStudy.company} logo`}
              className="desktop:w-48 laptop:w-48 tablet:w-36 mobile:w-28 h-auto desktop:mb-8 laptop:mb-8 tablet:mb-8 mobile:mb-12"
            />
            <div className="desktop:flex laptop:flex tablet:flex mobile:grid mobile:grid-cols-2 desktop:gap-8 laptop:gap-8 tablet:gap-6 mobile:gap-4 mb-6">
              <div className="mobile:hidden desktop:block laptop:block tablet:block">
                <p className="desktop:text-4xl laptop:text-4xl tablet:text-3xl mobile:text-xl font-bold text-yellow-400">
                  {caseStudy.metrics[0].value}
                </p>
                <p className="text-white/80">{caseStudy.metrics[0].label}</p>
              </div>
              <div className="mobile:mb-4">
                <p className="desktop:text-4xl laptop:text-4xl tablet:text-3xl mobile:text-xl font-bold text-yellow-400">
                  {caseStudy.metrics[1].value}
                </p>
                <p className="text-white/80">{caseStudy.metrics[1].label}</p>
              </div>
              <div className="mobile:order-first mobile:mb-4">
                <p className="desktop:text-4xl laptop:text-4xl tablet:text-3xl mobile:text-xl font-bold text-yellow-400">
                  {caseStudy.metrics[2].value}
                </p>
                <p className="text-white/80">{caseStudy.metrics[2].label}</p>
              </div>
            </div>
            <p className="desktop:text-2xl laptop:text-2xl tablet:text-xl mobile:text-base text-white/90 italic">
              "{caseStudy.testimonial}"
            </p>{" "}
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default CaseStudies;
