import { motion } from "framer-motion";

const CaseStudyDetail = () => {
  return (
    <div className="pt-16">
      <div className="bg-white">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="py-24"
          >
            <div className="desktop:flex laptop:flex tablet:block mobile:block gap-12">
              <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full">
                <img
                  src="https://createsiteai.b-cdn.net/c/_/38da728e-28be-4704-85d2-05d94da0f44b"
                  alt="ECOVEA logo"
                  className="w-48 mb-8"
                />
                <h1 className="text-4xl font-bold text-slate-800 mb-6">
                  Comment ECOVEA accélère son développement avec EtudeSolaire
                </h1>
                <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-3 mobile:grid-cols-1 gap-8 my-12">
                  <div className="bg-slate-50 p-6 rounded-xl">
                    <p className="text-3xl font-bold text-yellow-600 mb-2">
                      +45%
                    </p>
                    <p className="text-slate-600">Installations/mois</p>
                  </div>
                  <div className="bg-slate-50 p-6 rounded-xl">
                    <p className="text-3xl font-bold text-yellow-600 mb-2">
                      20h
                    </p>
                    <p className="text-slate-600">Temps économisé</p>
                  </div>
                  <div className="bg-slate-50 p-6 rounded-xl">
                    <p className="text-3xl font-bold text-yellow-600 mb-2">
                      +38%
                    </p>
                    <p className="text-slate-600">Taux de conversion</p>
                  </div>
                </div>
              </div>
              <div className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full">
                <img
                  src="https://createsiteai.b-cdn.net/c/_/74f7f35f-dd9c-4f7c-bec0-2287c78fa8f1"
                  alt="ECOVEA installation"
                  className="w-full h-auto rounded-xl"
                />
              </div>
            </div>

            <div className="mt-24">
              <h2 className="text-3xl font-bold text-slate-800 mb-8">
                Le défi
              </h2>
              <p className="text-xl text-slate-600 mb-12">
                ECOVEA cherchait à optimiser son processus d'étude technique et
                de vente pour accompagner sa croissance rapide, tout en
                maintenant la qualité de service qui fait sa réputation.
              </p>

              <h2 className="text-3xl font-bold text-slate-800 mb-8">
                La solution
              </h2>
              <p className="text-xl text-slate-600 mb-12">
                Avec EtudeSolaire, ECOVEA a pu digitaliser l'ensemble de son
                processus d'étude et de vente, permettant une meilleure
                coordination entre les équipes et une réduction significative
                des délais de traitement.
              </p>

              <blockquote className="bg-slate-50 p-8 rounded-xl mb-12">
                <p className="text-xl italic text-slate-800 mb-4">
                  "EtudeSolaire a transformé notre façon de travailler. La
                  solution nous permet d'être plus efficaces dans nos études
                  techniques et plus convaincants dans nos propositions
                  commerciales."
                </p>
                <p className="text-slate-600">Direction ECOVEA</p>
              </blockquote>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyDetail;
