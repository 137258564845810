import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";

const Hero = () => {
  return (
    <div className="relative desktop:min-h-screen laptop:min-h-screen tablet:min-h-screen mobile:min-h-fit bg-slate-50 overflow-hidden">
      <div className="absolute inset-0 z-0">
        <svg width="100%" height="100%" className="opacity-5">
          <defs>
            <pattern
              id="smallGrid"
              width="10"
              height="10"
              patternUnits="userSpaceOnUse"
            >
              <path
                d="M 10 0 L 0 0 0 10"
                fill="none"
                stroke="#ca8a04"
                strokeWidth="0.2"
              />
            </pattern>
            <pattern
              id="grid"
              width="50"
              height="50"
              patternUnits="userSpaceOnUse"
            >
              <rect width="50" height="50" fill="url(#smallGrid)" />
              <path
                d="M 50 0 L 0 0 0 50"
                fill="none"
                stroke="#ca8a04"
                strokeWidth="0.4"
              />
            </pattern>
            <radialGradient id="techGlow" cx="50%" cy="50%" r="50%">
              <stop offset="0%" stopColor="#ca8a04" stopOpacity="0.2" />
              <stop offset="100%" stopColor="#0066cc" stopOpacity="0" />
            </radialGradient>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid)" />
          <circle cx="50%" cy="50%" r="50%" fill="url(#techGlow)" />
        </svg>
      </div>
      <div className="absolute inset-0 z-0">
        <motion.svg
          viewBox="0 0 100 100"
          className="w-full h-full opacity-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 2 }}
        >
          <path
            d="M0,50 Q25,50 50,25 T100,50"
            fill="none"
            stroke="#ca8a04"
            strokeWidth="0.2"
            strokeDasharray="1,2"
          />
          <path
            d="M0,75 Q25,75 50,50 T100,75"
            fill="none"
            stroke="#ca8a04"
            strokeWidth="0.2"
            strokeDasharray="1,2"
          />
        </motion.svg>
      </div>
      <div className="relative z-10 container mx-auto px-4">
        <div className="desktop:flex laptop:flex tablet:block mobile:block items-center justify-between min-h-screen desktop:py-16 laptop:py-16 tablet:py-16 mobile:py-24 gap-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:w-5/12 laptop:w-5/12 tablet:w-full mobile:w-full"
          >
            <div className="relative">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="mb-8"
              >
                <h1 className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-3xl font-bold text-slate-800 mb-6 leading-tight">
                  Logiciel{" "}
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-400">
                    tout-en-un
                  </span>{" "}
                  pour vendre des systèmes solaires
                  <motion.span
                    initial={{ width: "0%" }}
                    animate={{ width: "100%" }}
                    transition={{ delay: 0.5, duration: 0.8 }}
                    className="absolute bottom-2 left-0 h-3 bg-yellow-200/30 -z-10"
                  />
                </h1>
              </motion.div>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="text-lg text-slate-600 mb-12 leading-relaxed"
              >
                La solution digitale qui révolutionne vos projets solaires.
                Concevez, vendez et installez plus efficacement grâce à notre
                suite logicielle intégrée.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="flex gap-6 flex-wrap"
              >
                <Link
                  to="#trial-form"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("trial-form")
                      ?.scrollIntoView({ behavior: "smooth" });
                  }}
                  className="group relative overflow-hidden bg-yellow-600 text-white px-8 py-4 rounded-lg shadow-lg shadow-yellow-600/20 transition-all duration-300 hover:shadow-xl hover:shadow-yellow-600/30"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 transition-transform duration-300 group-hover:scale-110" />
                  <span className="relative flex items-center gap-3 font-semibold">
                    Essai gratuit 30 jours
                    <svg
                      className="w-5 h-5 transition-transform duration-300 group-hover:translate-x-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </span>
                </Link>
              </motion.div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:w-6/12 laptop:w-6/12 tablet:w-full mobile:w-full desktop:mt-0 laptop:mt-0 tablet:mt-16 mobile:mt-16"
          >
            <VideoPlayer />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
