import { motion } from "framer-motion";
import { ReactNode } from "react";
import useResponsiveLayout from "../hooks/useResponsiveLayout";

interface ResponsiveContainerProps {
  children: ReactNode;
  className?: string;
}

const ResponsiveContainer = ({
  children,
  className = "",
}: ResponsiveContainerProps) => {
  const deviceType = useResponsiveLayout();

  const containerVariants = {
    mobile: { scale: 0.95 },
    tablet: { scale: 0.98 },
    laptop: { scale: 0.99 },
    desktop: { scale: 1 },
  };

  return (
    <motion.div
      animate={deviceType}
      variants={containerVariants}
      transition={{ duration: 0.3 }}
      className={`w-full ${className}`}
    >
      {children}
    </motion.div>
  );
};

export default ResponsiveContainer;
